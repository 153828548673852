import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import ContentWrapper from "../components/ContentWrapper";
import { Link } from "gatsby";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
`;

const MainInner = styled.div`
  text-align: center;
  padding: 30px 0;
  .notfound-title {
    font-size: 3rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 1.5rem;
    }
  }
  .notfound-link:hover {
    text-decoration: underline;
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="" description="" />
      <MainWrapper>
        <MainInner>
          <ContentWrapper>
            <h1 className="notfound-title">404 Page Not Found</h1>
            <Link to={`/`} className="notfound-link">ホームに戻る</Link>
          </ContentWrapper>
        </MainInner>
      </MainWrapper>
    </Layout>
  );
};

export default NotFoundPage;